import React from 'react';
import {
    Box,
    colors,
    Paper,
    Skeleton,
    Grid,
    Typography,
} from '@mui/material';

const AboutScreen = React.memo(() => {
    const [is_ocean_blast_screenshot_loaded, setIsOceanBlastScreenshotLoaded] = React.useState(false);
    const [is_pets_screenshot_loaded, setIsPetsScreenshotLoaded] = React.useState(false);

    return <React.Fragment>
        <Grid item xs={ 12 }>
            <Paper elevation={ 4 } sx={{
                'px': 4,
                'py': 2,
                'ml': 'auto',
                'mr': 'auto',
                'maxWidth': '980px',
            }}>
                <Grid container sx={{
                    'justifyContent': 'center',
                    'alignItems': 'center',
                }}>
                    <Grid item xs={ 12 } md={ 6 } order={{
                        'xs': 1,
                        'md': 0,
                    }} mt={{
                        'xs': 4,
                        'md': 0,
                    }}>
                        <Typography component='div' variant='h4' sx={{
                            'color': colors.grey[800],
                            'textAlign': {
                                'xs': 'center',
                                'md': 'left',
                            },
                        }}>
                            <b>About Us</b>
                        </Typography>

                        <Box display='flex' justifyContent='center' alignItems='center' mt={ 5 } columnGap={{
                            'xs': 4,
                            'sm': 6,
                        }}>
                            <svg xmlns='http://www.w3.org/2000/svg' width='64' height='64' fill={ colors.yellow[500] } viewBox='0 0 16 16'>
                                <path d='M10 2a2 2 0 0 1-1.5 1.937v5.087c.863.083 1.5.377 1.5.726 0 .414-.895.75-2 .75s-2-.336-2-.75c0-.35.637-.643 1.5-.726V3.937A2 2 0 1 1 10 2z'/>
                                <path d='M0 9.665v1.717a1 1 0 0 0 .553.894l6.553 3.277a2 2 0 0 0 1.788 0l6.553-3.277a1 1 0 0 0 .553-.894V9.665c0-.1-.06-.19-.152-.23L9.5 6.715v.993l5.227 2.178a.125.125 0 0 1 .001.23l-5.94 2.546a2 2 0 0 1-1.576 0l-5.94-2.546a.125.125 0 0 1 .001-.23L6.5 7.708l-.013-.988L.152 9.435a.25.25 0 0 0-.152.23z'/>
                            </svg>

                            <svg xmlns='http://www.w3.org/2000/svg' width='42' height='42' fill='#000' viewBox='0 0 16 16'>
                                <path fillRule='evenodd' d='M8 2a.5.5 0 0 1 .5.5v5h5a.5.5 0 0 1 0 1h-5v5a.5.5 0 0 1-1 0v-5h-5a.5.5 0 0 1 0-1h5v-5A.5.5 0 0 1 8 2Z'/>
                            </svg>

                            <svg xmlns='http://www.w3.org/2000/svg' width='64' height='64' fill={ colors.pink[400] } viewBox='0 0 16 16'>
                                <path fillRule='evenodd' d='M8 1.314C12.438-3.248 23.534 4.735 8 15-7.534 4.736 3.562-3.248 8 1.314z'/>
                            </svg>
                        </Box>

                        <Typography component='div' sx={{
                            'fontSize': 20,
                            'mt': 6,
                        }}>
                            Pandastic Games is dedicated to making great mobile experiences. If you'd like to contact us, please use the contact info below.
                        </Typography>

                        <Typography component='div' sx={{
                            'fontSize': 20,
                            'mt': 4,
                        }}>
                            <b>Contact:</b> <a href='mailto:info@pandasticgames.com' style={{
                                'color': '#000',
                                'wordWrap': 'break-word',
                            }}>
                                info@pandasticgames.com
                            </a>
                        </Typography>
                    </Grid>

                    <Grid item xs={ 12 } md={ 6 } order={{
                        'xs': 0,
                        'md': 1,
                    }} pl={{
                        'xs': 0,
                        'md': 6,
                    }}>
                        <Grid container rowGap={ 1 }>
                            <Grid item xs={ 12 } sm={ 6 } md={ 12 } maxHeight={{
                                'xs': '240px',
                                'md': '360px',
                            }} pr={{
                                'xs': 0,
                                'sm': 1,
                                'md': 0,
                            }}>
                                <img alt='' src='/images/ocean_blast_screenshot.png' style={{
                                    'borderRadius': 8,
                                    'display': is_ocean_blast_screenshot_loaded ? 'block' : 'none',
                                    'width': '100%',
                                    'height': 'auto',
                                    'maxHeight': 'inherit',
                                    'userSelect': 'none',
                                    'pointerEvents': 'none',
                                }} onLoad={ () => setIsOceanBlastScreenshotLoaded(true) }/>

                                { !is_ocean_blast_screenshot_loaded ? <Skeleton variant='rounded' sx={{
                                    'width': '100%',
                                    'height': '0',
                                    'pt': '53.75%',
                                    }}/> : undefined }
                            </Grid>

                            <Grid item xs={ 12 } sm={ 6 } md={ 12 } maxHeight={{
                                'xs': '240px',
                                'md': '360px',
                            }} pl={{
                                'xs': 0,
                                'sm': 1,
                                'md': 0,
                            }}>
                                <img alt='' src='/images/pets_screenshot.png' style={{
                                    'borderRadius': 8,
                                    'display': is_pets_screenshot_loaded ? 'block' : 'none',
                                    'width': '100%',
                                    'height': 'auto',
                                    'maxHeight': 'inherit',
                                    'userSelect': 'none',
                                    'pointerEvents': 'none',
                                }} onLoad={ () => setIsPetsScreenshotLoaded(true) }/>

                                { !is_pets_screenshot_loaded ? <Skeleton variant='rounded' sx={{
                                    'width': '100%',
                                    'height': '0',
                                    'pt': '53.75%',
                                    }}/> : undefined }
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Paper>
        </Grid>
    </React.Fragment>;
});

export default AboutScreen;
