import React from 'react';
import {
    Box,
    Button,
    colors,
    Typography,
    Grid,
    Link,
} from '@mui/material';

const Footer = React.memo(() => {
    return <Box width='100%' pb={ 2 } pt={ 2 } position='absolute' left='50%' bottom='0' sx={{
        'boxSizing': 'border-box',
        'backgroundColor': colors.grey[800],
        'color': colors.grey[500],
        'fontSize': 18,
        'textAlign': 'center',
        'transform': 'translateX(-50%)',
    }} px={{
        'xs': 2,
        'md': 4,
    }}>
        <Grid container justifyContent='space-between' rowGap={ 1 } maxWidth='1000px' ml='auto' mr='auto'>
            <Grid item xs={ 12 } md={ 6 } display='flex' alignItems='center' justifyContent={{
                'xs': 'center',
                'md': 'right',
            }}>
                <Typography component='div' sx={{
                    'color': colors.grey[300],
                }}>
                    Copyright &#169; { new Date().getFullYear() } <Link href='/' style={{
                        'color': colors.grey[100],
                        'textDecoration': 'none',
                    }}>Pandastic Games.</Link> All rights reserved.
                </Typography>
            </Grid>
            <Grid item xs={ 6 } md={ 3 } sx={{
                'textAlign': {
                    'xs': 'center',
                },
            }}>
                <Typography component='div'>
                    <Link href='/oceanblast/privacy'
                        sx={{
                            'color': colors.grey[100],
                            'textDecoration': 'none',
                        }}
                    >
                        Privacy
                    </Link>
                </Typography>
            </Grid>
            <Grid item xs={ 6 } md={ 3 } sx={{
                'textAlign': {
                    'xs': 'center',
                    'md': 'left',
                },
            }}>
                    <Button aria-label='Back To Top' variant='text' sx={{
                        'color': '#fff',
                        'textTransform': 'none',
                        'padding': '0',
                        'px': 1,
                    }} onClick={ () => window.scrollTo(0, 0) }>
                        Back To Top&nbsp;&nbsp;
                        <svg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='#fff' viewBox='0 0 16 16'>
                            <path fillRule='evenodd' d='M7.646 4.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1-.708.708L8 5.707l-5.646 5.647a.5.5 0 0 1-.708-.708l6-6z'/>
                        </svg>
                    </Button>
            </Grid>
        </Grid>
    </Box>;
});

export default Footer;
