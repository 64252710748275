import React from 'react';
import {
    AppBar,
    Box,
    Button,
    colors,
    Container,
    IconButton,
    Toolbar,
} from '@mui/material';

const Header = React.memo((props) => {
    return <AppBar position='fixed' sx={{
        'backgroundImage': `linear-gradient(to bottom, ${ colors.grey[700] }, ${ colors.grey[800] })`,
    }}>
        <Container maxWidth='sm'>
            <Toolbar disableGutters sx={{
                'flexWrap': 'wrap',
                'justifyContent': props['is_vertical'] ? 'center' : 'space-between',
                'pb': props['is_vertical'] ? 2 : 0,
                'rowGap': 1,
            }}>
                <IconButton aria-label='Pandastic Games' href='/' sx={{
                    'borderRadius': 4,
                }}>
                    <img alt='' src='/images/logo.png'/>
                </IconButton>

                <Box display='flex' gap={ 2 }>
                    { props['menu_items'].map((menu_item, i) =>
                        <Button aria-label={ menu_item['label'] } key={ i } disabled={ i === props['active_menu_item'] } variant='text' sx={{
                            'color': colors.grey[100],
                            'fontSize': 26,
                            'textTransform': 'none',
                            'textShadow': '0 1px 1px rgb(0 0 0)',
                            '&.Mui-disabled': {
                                'backgroundImage': `linear-gradient(to bottom, ${ colors.lightBlue[600] }, ${ colors.lightBlue[700] })`,
                                'color': colors.grey[100],
                            },
                        }} onClick={ () => props['on_active_menu_item_changed'](menu_item) }>
                            { menu_item['label'] }
                        </Button>
                    )}
                </Box>
            </Toolbar>
        </Container>
    </AppBar>;
});

export default Header;
