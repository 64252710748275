import React from 'react';
import GameShowcaseEntry from './GameShowcaseEntry';

const GameShowcaseScreen = React.memo((props) => {
    return <React.Fragment>
        { props['games'].map((game, i) => <GameShowcaseEntry
            key={ i }
            banner_src={ game['banner_src'] }
            description={ game['description'] }
            on_click={ game['on_click'] }
        /> )}
    </React.Fragment>;
});

export default GameShowcaseScreen;
