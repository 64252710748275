import React, { useEffect } from 'react';
import FacebookIcon from '@mui/icons-material/Facebook';
import PollIcon from '@mui/icons-material/Poll';
import VolumeUpIcon from '@mui/icons-material/VolumeUp';
import {
    Box,
    colors,
    Button,
    Grid,
    Typography,
} from '@mui/material';
import { styled } from '@mui/system';
import { useLocation } from 'react-router-dom/cjs/react-router-dom';

const AStyled = styled('a')({
    'color': colors.blue[400],
    'textDecoration': 'none',
}); 

const PolicySectionTitle = styled(Typography)({
    'borderBottom': `1px solid ${ colors.grey[400] }`,
    'fontSize': 17,
    'textAlign': 'left',
});

const PolicySectionSubtitle = styled(Typography)({
    'color': colors.grey[900],
    'fontSize': 16,
    'textAlign': 'left',
    'marginTop': 16,
});

const PolicySectionBody = styled(Typography)({
    'color': colors.grey[700],
    'fontSize': 14,
    'textAlign': 'left',
    'paddingLeft': 8,
    'paddingRight': 8,
});

const DataDeletionStep = ({ step_description, alt, src }) => {
    return <Grid item
        xs={ 6 }
        md={ 3 }
        textAlign={{
            'xs': 'center',
            'md': 'left',
        }}
    >
        <Box
            component='img'
            sx={{
                'maxWidth': {
                    'xs': '130px',
                    'sm': '200px',
                },
                'marginBottom': '5px'
            }}
            alt={ alt }
            src={ src }
        />
        <Typography>
            { step_description }
        </Typography>
    </Grid>
}

const PrivacyPolicyScreen = React.memo((props) => {
    const purposes_services = [
        {
            'title': '3rd Party Access',
            'subtitle': 'Access to the Facebook account',
            'details': 'Permissions: Access Friend Lists and Contact email',
            'icon_component': <FacebookIcon sx={{ 'fontSize': 48 }}/>,
        },
        {
            'title': 'Advertising',
            'subtitle': <React.Fragment>
                <AStyled href='https://answers.chartboost.com/en-us/articles/200780269'>Chartboost</AStyled>,
                &nbsp;<AStyled href='http://www.adcolony.com/privacy-policy/'>AdColony</AStyled>
            </React.Fragment>,
            'details': 'Personal Data: Usage data and Various types of Data as specified in the privacy policy of the services',
            'icon_component': <VolumeUpIcon sx={{ 'fontSize': 48 }}/>,
        },
        {
            'title': 'Analytics and Notifications',
            'subtitle': <React.Fragment>
                <AStyled href='https://unity3d.com/legal/privacy-policy'>Unity Analytics</AStyled>,
                &nbsp;<AStyled href='https://www.appsflyer.com/privacy-policy/'>AppsFlyer</AStyled>,
                &nbsp;<AStyled href='https://onesignal.com/privacy_policy'>OneSignal</AStyled>
            </React.Fragment>,
            'details': 'Personal Data: Usage data and Various types of Data as specified in the privacy policy of the services',
            'icon_component': <PollIcon sx={{ 'fontSize': 48 }}/>,
        },
    ];

    const location = useLocation();
    
    useEffect(() => {
        if (!location.hash) {
            return;
        }
        const element = document.getElementById(location.hash.substring(1));
        if (element) {
            element.scrollIntoView({ behavior: 'smooth'});
        }
    }, [location]);

    return <React.Fragment>
        <Box position='absolute' left='0' top='0' pt={ 1 } pl={ 1.5 }>
            <Button aria-label='Home' variant='text' sx={{
                'color': '#000',
                'fontSize': 18,
                'textTransform': 'none',
            }} onClick={ props['on_home_click'] }>
                <svg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='#000' viewBox='0 0 16 16'>
                    <path fillRule='evenodd' d='M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z'/>
                </svg>
                &nbsp;&nbsp;Home
            </Button>
        </Box>

        <Grid item xs={ 12 } px={ 2 } pt={ 10 } pb={ 4 } textAlign='center'>
            <Box textAlign='center'>
                <img alt='' width='80' height='80' src={ props['game_icon'] }/>
            </Box>

            <Typography component='div' variant='h5' mt={ 2 }>
                Privacy Policy of { props['game_title'] }
            </Typography>

            <Typography component='div' variant='body1' fontSize={ 16 } mt={ 2 }>
                This Application collects some Personal Data from its Users.
            </Typography>
        </Grid>

        <Grid item xs={ 12 } backgroundColor={ `${ colors['grey'][100] }` } px={ 2 } pt={ 6 } pb={ 4 } textAlign='center'>
            <Typography component='div' variant='h5'>
                Policy Summary
            </Typography>

            <Typography component='div' variant='body1' fontSize={ 16 } mt={ 2 }>
                Personal Data is collected for the following purposes and using the following services:
            </Typography>

            <Grid container maxWidth='md' mt={ 4 } ml='auto' mr='auto' px={ 2 } rowGap={ 6 }>
                { purposes_services.map((entry, i) => <Grid item key={ i } xs={ 12 } md={ 4 } px={ 2 }>
                    <Box maxWidth='280px' ml='auto' mr='auto'>
                        <Box
                            alignItems='center'
                            borderRadius='50%'
                            backgroundColor={ colors.blue[500] }
                            color={ colors.grey[50] }
                            display='flex'
                            justifyContent='center'
                            ml='auto'
                            mr='auto'
                            width='80px'
                            height='80px'
                        >
                            { entry['icon_component'] }
                        </Box>

                        <Typography component='div' variant='body1' borderBottom={ `1px solid ${ colors.grey[400] }` } fontSize={ 17 } mt={ 2 }>
                            { entry['title'] }
                        </Typography>

                        <Typography component='div' variant='body1' mt={ 2 } px={ 1 }>
                            { entry['subtitle'] }
                        </Typography>

                        <Typography component='div' variant='body1' color={ colors.grey[600] } fontSize={ 14 } mt={ 1 } px={ 1 }>
                            { entry['details'] }
                        </Typography>
                    </Box>
                </Grid>) }
            </Grid>
        </Grid>

        <Grid item xs={ 12 } px={ 2 } pt={ 6 } pb={ 4 } textAlign='center'>
            <Grid container maxWidth='820px' ml='auto' mr='auto' rowGap={ 3 }>
                <Grid item xs={ 12 }>
                    <Typography component='div' variant='h5'>
                        Full Policy
                    </Typography>
                </Grid>

                <Grid item xs={ 12 }>
                    <PolicySectionTitle>Types of Data collected</PolicySectionTitle>

                    <PolicySectionBody component='div'>
                        <p>Among the types of Personal Data that this Application collects, by itself or through third parties, there are: Cookie and Usage data.</p>
                        <p>Other Personal Data collected may be described in other sections of this privacy policy or by dedicated explanation text contextually with the Data collection.</p>
                        <p>The Personal Data may be freely provided by the User, or collected automatically when using this Application.</p>
                        <p>Any use of Cookies - or of other tracking tools - by this Application or by the owners of third party services used by this Application, unless stated otherwise, serves to identify Users and remember their preferences, for the sole purpose of providing the service required by the User.</p>
                        <p>Failure to provide certain Personal Data may make it impossible for this Application to provide its services.</p>
                        <p>Users are responsible for any Personal Data of third parties obtained, published or shared through this Application and confirm that they have the third party's consent to provide the Data to the Owner.</p>
                    </PolicySectionBody>
                </Grid>

                <Grid item xs={ 12 }>
                    <PolicySectionTitle>Mode and place of processing the Data</PolicySectionTitle>

                    <PolicySectionBody component='div'>
                        <PolicySectionSubtitle>Methods of processing</PolicySectionSubtitle>
                        <p>The Data Controller processes the Data of Users in a proper manner and shall take appropriate security measures to prevent unauthorized access, disclosure, modification, or unauthorized destruction of the Data.</p>
                        <p>The Data processing is carried out using computers and/or IT enabled tools, following organizational procedures and modes strictly related to the purposes indicated. In addition to the Data Controller, in some cases, the Data may be accessible to certain types of persons in charge, involved with the operation of the site (administration, sales, marketing, legal, system administration) or external parties (such as third party technical service providers, mail carriers, hosting providers, IT companies, communications agencies) appointed, if necessary, as Data Processors by the Owner. The updated list of these parties may be requested from the Data Controller at any time.</p>

                        <PolicySectionSubtitle>Place</PolicySectionSubtitle>
                        <p>The Data is processed at the Data Controller's operating offices and in any other places where the parties involved with the processing are located. For further information, please contact the Data Controller.</p>
                    
                        <PolicySectionSubtitle>Retention time</PolicySectionSubtitle>
                        <p>The Data is kept for the time necessary to provide the service requested by the User, or stated by the purposes outlined in this document, and the User can always request that the Data Controller suspend or remove the data.</p>
                    </PolicySectionBody>
                </Grid>

                <Grid item xs={ 12 }>
                    <PolicySectionTitle>The use of the collected Data</PolicySectionTitle>

                    <PolicySectionBody component='div'>
                        <p>The Data concerning the User is collected to allow the Owner to provide its services, as well as for the following purposes: Advertising, Analytics and Access to third party services' accounts.</p>
                        <p>The Personal Data used for each purpose is outlined in the specific sections of this document.</p>
                    </PolicySectionBody>
                </Grid>

                <Grid item xs={ 12 }>
                    <PolicySectionTitle>Facebook permissions asked by this Application</PolicySectionTitle>

                    <PolicySectionBody component='div'>
                        <p>This Application may ask for some Facebook permissions allowing it to perform actions with the User's Facebook account and to retrieve information, including Personal Data, from it.</p>
                        <p>For more information about the following permissions, refer to the <AStyled href='https://developers.facebook.com/docs/authentication/permissions/'>Facebook permissions documentation</AStyled> and to the <AStyled href='https://www.facebook.com/about/privacy/'>Facebook privacy policy</AStyled>.</p>
                        <p>The permissions asked are the following:</p>

                        <Box pl={ 1.5 }>
                            <PolicySectionSubtitle>Basic information</PolicySectionSubtitle>
                            <p>By default, this includes certain User's Data such as id, name, picture, gender, and their locale. Certain connections of the User, such as the Friends, are also available. If the user has made more of their data public, more information will be available.</p>
                        
                            <PolicySectionSubtitle>Access Friend Lists</PolicySectionSubtitle>
                            <p>Provides access to any friend lists the user created.</p>

                            <PolicySectionSubtitle>Contact email</PolicySectionSubtitle>
                            <p>Access the user's contact email address.</p>
                        </Box>
                    </PolicySectionBody>
                </Grid>

                <Grid item xs={ 12 }>
                    <PolicySectionTitle>Additional information about Data collection and processing</PolicySectionTitle>

                    <PolicySectionBody component='div'>
                        <PolicySectionSubtitle>Legal action</PolicySectionSubtitle>
                        <p>The User's Personal Data may be used for legal purposes by the Data Controller, in Court or in the stages leading to possible legal action arising from improper use of this Application or the related services.</p>
                        <p>The User declares to be aware that the Data Controller may be required to reveal personal data upon request of public authorities.</p>

                        <PolicySectionSubtitle>Additional information about User's Personal Data</PolicySectionSubtitle>
                        <p>In addition to the information contained in this privacy policy, this Application may provide the User with additional and contextual information concerning particular services or the collection and processing of Personal Data upon request.</p>

                        <PolicySectionSubtitle>System Logs and Maintenance</PolicySectionSubtitle>
                        <p>For operation and maintenance purposes, this Application and any third party services may collect files that record interaction with this Application (System Logs) or use for this purpose other Personal Data (such as IP Address).</p>

                        <PolicySectionSubtitle>Information not contained in this policy</PolicySectionSubtitle>
                        <p>More details concerning the collection or processing of Personal Data may be requested from the Data Controller at any time. Please see the contact information at the end of this document.</p>

                        <PolicySectionSubtitle>The rights of Users</PolicySectionSubtitle>
                        <p>Users have the right, at any time, to know whether their Personal Data has been stored and can consult the Data Controller to learn about their contents and origin, to verify their accuracy or to ask for them to be supplemented, cancelled, updated or corrected, or for their transformation into anonymous format or to block any data held in violation of the law, as well as to oppose their treatment for any and all legitimate reasons. Requests should be sent to the Data Controller at the contact information set out above.</p>

                        <PolicySectionSubtitle>Changes to this privacy policy</PolicySectionSubtitle>
                        <p>The Data Controller reserves the right to make changes to this privacy policy at any time by giving notice to its Users on this page. It is strongly recommended to check this page often, referring to the date of the last modification listed at the bottom. If a User objects to any of the changes to the Policy, the User must cease using this Application and can request that the Data Controller removes the Personal Data. Unless stated otherwise, the then-current privacy policy applies to all Personal Data the Data Controller has about Users.</p>
                    </PolicySectionBody>
                </Grid>

                { props['game_title'] === 'Ocean Blast' && <React.Fragment>
                <Grid item xs={ 12 }>
                    <PolicySectionTitle id='data-deletion'>Data deletion</PolicySectionTitle>

                    <PolicySectionBody component='div'>
                        <PolicySectionSubtitle>Data deletion policy</PolicySectionSubtitle>
                        <p>To provide our users with control over their personal data, we offer a straightforward data deletion process.</p>
                        <p>Upon clicking the 'Delete Data' button, all data linked to the user's account will be promptly and irreversibly deleted from our servers.</p>
                        <PolicySectionSubtitle>Deletion procedure</PolicySectionSubtitle>
                            <Grid container spacing={ 3 } mt={ 1 }>
                                <DataDeletionStep 
                                    step_description='First step: Click the settings icon at the bottom of the screen;'
                                    alt='First step for data deletion'
                                    src='/images/deletion-steps/1.jpg'
                                />
                                <DataDeletionStep 
                                    step_description='Second step: Click the Delete Data button;'
                                    alt='Second step for data deletion'
                                    src='/images/deletion-steps/2.jpg'
                                />
                                <DataDeletionStep 
                                    step_description='Third step: Confirm by clicking the Sure button;'
                                    alt='Third step for data deletion'
                                    src='/images/deletion-steps/3.jpg'
                                />
                                <DataDeletionStep 
                                    step_description='Your data has been deleted!'
                                    alt='Fourth step for data deletion'
                                    src='/images/deletion-steps/4.jpg'
                                />
                            </Grid>
                    </PolicySectionBody>
                </Grid>
                </React.Fragment> }

                <Grid item xs={ 12 }>
                    <PolicySectionTitle>Contact Information</PolicySectionTitle>

                    <PolicySectionBody component='div'>
                        <p><AStyled href='mailto:privacy@pandasticgames.com'>privacy@pandasticgames.com</AStyled></p>
                    </PolicySectionBody>
                </Grid>

                <Grid item xs={ 12 }>
                    <PolicySectionTitle>Definitions and legal references</PolicySectionTitle>

                    <PolicySectionBody component='div'>
                        <PolicySectionSubtitle>Personal Data (or Data)</PolicySectionSubtitle>
                        <p>Any information regarding a natural person, a legal person, an institution or an association, which is, or can be, identified, even indirectly, by reference to any other information, including a personal identification number.</p>
                    
                        <PolicySectionSubtitle>Usage Data</PolicySectionSubtitle>
                        <p>Information collected automatically from this Application (or third party services employed in this Application), which can include: the IP addresses or domain names of the computers utilized by the Users who use this Application, the URI addresses (Uniform Resource Identifier), the time of the request, the method utilized to submit the request to the server, the size of the file received in response, the numerical code indicating the status of the server's answer (successful outcome, error, etc.), the country of origin, the features of the browser and the operating system utilized by the User, the various time details per visit (e.g., the time spent on each page within the Application) and the details about the path followed within the Application with special reference to the sequence of pages visited, and other parameters about the device operating system and/or the User's IT environment.</p>

                        <PolicySectionSubtitle>User</PolicySectionSubtitle>
                        <p>The individual using this Application, which must coincide with or be authorized by the Data Subject, to whom the Personal Data refer.</p>
                    
                        <PolicySectionSubtitle>Data Subject</PolicySectionSubtitle>
                        <p>The legal or natural person to whom the Personal Data refers.</p>

                        <PolicySectionSubtitle>Data Processor (or Data Supervisor)</PolicySectionSubtitle>
                        <p>The natural person, legal person, public administration or any other body, association or organization authorized by the Data Controller to process the Personal Data in compliance with this privacy policy.</p>

                        <PolicySectionSubtitle>Data Controller (or Owner)</PolicySectionSubtitle>
                        <p>The natural person, legal person, public administration or any other body, association or organization with the right, also jointly with another Data Controller, to make decisions regarding the purposes, and the methods of processing of Personal Data and the means used, including the security measures concerning the operation and use of this Application. The Data Controller, unless otherwise specified, is the Owner of this Application.</p>

                        <PolicySectionSubtitle>This Application</PolicySectionSubtitle>
                        <p>The hardware or software tool by which the Personal Data of the User is collected.</p>

                        <PolicySectionSubtitle>Cookie</PolicySectionSubtitle>
                        <p>Small piece of data stored in the User's device.</p>
                    </PolicySectionBody>
                </Grid>

                <Grid item xs={ 12 }>
                    <PolicySectionTitle>Legal information</PolicySectionTitle>

                    <PolicySectionBody component='div'>
                        <p>Notice to European Users: this privacy statement has been prepared in fulfillment of the obligations under Art. 10 of EC Directive n. 95/46/EC, and under the provisions of Directive 2002/58/EC, as revised by Directive 2009/136/EC, on the subject of Cookies.</p>
                        <p>This privacy policy relates solely to this Application.</p>
                    </PolicySectionBody>
                </Grid>
            </Grid>
        </Grid>
    </React.Fragment>;
});

export default PrivacyPolicyScreen;