import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { colors } from '@mui/material';
import App from './App';

const root_node = document.getElementById('root');
const root = ReactDOM.createRoot(root_node);

document.body.style.backgroundImage = `linear-gradient(55deg, ${ colors.blueGrey[50] }, ${ colors.blueGrey[100] })`;
document.body.style.margin = 0;

root_node.style.minHeight = '100vh';
root_node.style.position = 'relative';

root.render(<React.StrictMode>
    <BrowserRouter>
        <App/>
    </BrowserRouter>
</React.StrictMode>);
