import React from 'react';
import { useHistory, Switch, Route } from 'react-router-dom';

import AboutScreen from './AboutScreen';
import Footer from './Footer';
import GameShowcaseScreen from './GameShowcaseScreen';
import Header from './Header';
import LandingPageScreen from './LandingPageScreen';
import PrivacyPolicyScreen from './PrivacyPolicyScreen';

import {
    useMediaQuery,
    Container,
    Grid,
} from '@mui/material';

function App() {
    const [is_header_visible, setIsHeaderVisible] = React.useState(false);
    const is_header_vertical = useMediaQuery('(max-width: 448px)', {
        'noSsr': true,
    });
    const history = useHistory();

    const menu_items = React.useMemo(() => [
        {
            'label': 'Games',
            'path': '/',
        },
        {
            'label': 'About',
            'path': '/about',
        },
    ], []);

    const route_options = React.useMemo(() => {
        return {
            'favicons': {
                '*': '/images/favicons/main.png',
                '/oceanblast': '/images/favicons/oceanblast.png',
                '/oceanblast/privacy': '/images/favicons/oceanblast.png',
                '/hungrypets/privacy': '/images/favicons/hungrypets.png',
            },
            'titles': {
                '*': 'Pandastic Games',
                '/oceanblast': 'Ocean Blast',
                '/oceanblast/privacy': 'Ocean Blast - Privacy Policy',
                '/hungrypets/privacy': 'Hungry Pet Mania - Privacy Policy',
            },
            'menu_visibility': {
                '*': true,
                '/oceanblast': false,
                '/oceanblast/privacy': false,
                '/hungrypets/privacy': false,
            },
        };
    }, []);

    const [active_menu_item, setActiveMenuItem] = React.useState(menu_items.indexOf(menu_items.filter((item) => item['path'] === history.location.pathname)[0] || menu_items[0]));

    React.useEffect(() => {
        function updateHeader(pathname) {
            if (typeof route_options['menu_visibility'][pathname] === 'boolean') {
                setIsHeaderVisible(route_options['menu_visibility'][pathname]);
            } else {
                setIsHeaderVisible(route_options['menu_visibility']['*']);
            }

            const index = menu_items.indexOf(menu_items.filter((item) => item['path'] === pathname)[0]);

            if (index < 0) {
                return;
            }

            setActiveMenuItem(index);
        }

        function updateFavicon(pathname) {
            if (route_options['favicons'][pathname]) {
                document.querySelector("link[rel*='icon']").href = route_options['favicons'][pathname];
                return;
            }

            document.querySelector("link[rel*='icon']").href = route_options['favicons']['*'];
        }

        function updateTitle(pathname) {
            if (route_options['titles'][pathname]) {
                document.title = route_options['titles'][pathname];
                return;
            }

            document.title = route_options['titles']['*'];
        }

        history.listen((e) => {
            updateHeader(e.pathname);
            updateFavicon(e.pathname);
            updateTitle(e.pathname);

            window.scrollTo(0, 0);
        });

        const pathname = document.location.pathname.replace(/\/+$/, '');

        updateHeader(pathname);
        updateFavicon(pathname);
        updateTitle(pathname);
    }, [history, menu_items, route_options]);

    function ScreenGridContainer(props) {
        let pt = 0;

        if (is_header_visible) {
            pt = is_header_vertical ? 26 : 16;
        }

        return <Grid container maxWidth='lg' ml='auto' mr='auto' rowGap={ 5 } px={ 3 } pb={ 4 } pt={ pt }>
            { props['children'] }
        </Grid>
    }

    return <Container sx={{
        'pb': {
            'xs': 14,
            'sm': 12,
            'md': 10,
        },
    }} style={{
        'paddingLeft': 0,
        'paddingRight': 0,
        'maxWidth': 'unset',
    }}>
        { is_header_visible ? <Header active_menu_item={ active_menu_item } is_vertical={ is_header_vertical } menu_items={ menu_items } on_active_menu_item_changed={ (item) => {
            setActiveMenuItem(menu_items.indexOf(item));
            history.push(item['path']);
        }}/> : undefined }

        <Switch>
            <Route path='/about'>
                <ScreenGridContainer><AboutScreen/></ScreenGridContainer>
            </Route>

            <Route path='/oceanblast/privacy'>
                <Grid container>
                    <PrivacyPolicyScreen
                        game_title='Ocean Blast'
                        game_icon='/images/landing-pages/oceanblast/icon_big.png'
                        on_home_click={ () => history.push('/') }
                    />
                </Grid>
            </Route>

            <Route path='/hungrypets/privacy'>
                <Grid container>
                    <PrivacyPolicyScreen
                        game_title='Hungry Pet Mania'
                        game_icon='/images/hungrypets_icon.png'
                        on_home_click={ () => history.push('/') }
                    />
                </Grid>
            </Route>

            <Route path='/oceanblast'>
                <Grid container>
                    <LandingPageScreen
                        game_name='Ocean Blast'
                        game_description='A match-3 puzzle game with strategic elements.'
                        game_icon='/images/landing-pages/oceanblast/icon_big.png'
                        appstore_url='https://apps.apple.com/app/id1635981427'
                        google_play_url='https://play.google.com/store/apps/details?id=com.pandastic.oceanblast'
                        youtube_url='https://www.youtube.com/watch?v=Hgi0KGAlTJ0'
                        youtube_embed_url='https://www.youtube.com/embed/Hgi0KGAlTJ0'
                        splash_background_image='/images/landing-pages/oceanblast/splash-bg.jpg'
                        ipad_image='/images/landing-pages/oceanblast/ipad.jpg'
                        iphone_image='/images/landing-pages/oceanblast/iphone.jpg'
                        features={[
                            {
                                'title': 'Connect & Catch Fishies',
                                'description': 'Use your finger to match lines of 3 or more fishies on hexagonal boards.',
                                'image': '/images/landing-pages/oceanblast/feature1.jpg',
                            },
                            {
                                'title': 'Beat Epic Bosses',
                                'description': 'Explore the deeps and conquer epic bosses!',
                                'image': '/images/landing-pages/oceanblast/feature2.jpg',
                            },
                            {
                                'title': 'Trigger Chain Reactions',
                                'description': 'Connect Flashy Fishies for massive combos and trigger awesome chain reactions!',
                                'image': '/images/landing-pages/oceanblast/feature3.jpg',
                            },
                            {
                                'title': 'Rescue Endangered Species',
                                'description': 'Rescue all kinds of ultra-cute creatures!',
                                'image': '/images/landing-pages/oceanblast/feature4.jpg',
                            },
                            {
                                'title': 'Play With Friends',
                                'description': 'Explore the deeps with your Facebook friends!',
                                'image': '/images/landing-pages/oceanblast/feature5.jpg',
                            },
                            {
                                'title': 'Plenty To Do',
                                'description': '100+ levels get more challenging thanks to new fishies with special abilities!',
                                'image': '/images/landing-pages/oceanblast/feature6.jpg',
                            },
                        ]}
                        on_home_click={ () => history.push('/') }
                    />
                </Grid>
            </Route>

            <Route path='*'>
                <ScreenGridContainer>
                    <GameShowcaseScreen
                        games={[
                            {
                                'banner_src': '/images/game_ocean.png',
                                'description': 'Bait your hook, strap on your snorkel, and dive deep into Ocean Blast - a match-3 puzzle game full of lively fishies ripe for the catching. Can you match all the right ones before you run out of moves?',
                                'on_click': () => history.push('/oceanblast'),
                            },
                        ]}
                    />
                </ScreenGridContainer>
            </Route>
        </Switch>

        <Footer/>
    </Container>;
}

export default App;
