import React from 'react';
import {
    Paper,
    Grid,
    IconButton,
    Skeleton,
    Typography,
} from '@mui/material';

const GameShowcaseEntry = React.memo((props) => {
    const [is_banner_loaded, setIsBannerLoaded] = React.useState(false);

    return <Grid item xs={ 12 }>
        <Paper elevation={ 4 } sx={{
            'px': 2,
            'py': 2,
            'ml': 'auto',
            'mr': 'auto',
            'maxWidth': '980px',
        }}>
            <IconButton aria-label='Game Banner' sx={{
                'borderRadius': 0,
                'padding': 0,
                'width': '100%',
            }} onClick={ () => props['on_click']() }>
                <img alt='' src={ props['banner_src'] } style={{
                    'borderRadius': 4,
                    'display': is_banner_loaded ? 'block' : 'none',
                    'width': '100%',
                    'height': 'auto',
                    'userSelect': 'none',
                    'pointerEvents': 'none',
                }} onLoad={ () => setIsBannerLoaded(true) }/>

                { !is_banner_loaded ? <Skeleton variant='rounded' sx={{
                    'width': '100%',
                    'height': '0',
                    'pt': '44.79%',
                }}/> : undefined }
            </IconButton>

            <Typography component='div' mt={ 2 } px={ 2 } sx={{
                'fontSize': {
                    'xs': 16,
                    'sm': 20,
                    'xl': 24,
                },
            }}>
                { props['description'] }
            </Typography>
        </Paper>
    </Grid>;
});

export default GameShowcaseEntry;
