import React from 'react';
import {
    useMediaQuery,
    Box,
    colors,
    Button,
    Grid,
    IconButton,
    Link,
    Paper,
    Skeleton,
    Typography,
} from '@mui/material';

const LandingPageScreen = React.memo((props) => {
    const is_ipad_visible = useMediaQuery('(min-width: 760px)', {
        'noSsr': true,
    });
    const [is_video_iframe_loaded, setIsVideoIframeLoaded] = React.useState(false);
    const [is_iphone_image_loaded, setIsIphoneImageLoaded] = React.useState(false);
    const [is_ipad_image_loaded, setIsIpadImageLoaded] = React.useState(false);

    const platforms = [];

    if (props['appstore_url']) {
        platforms.push('iOS');
    }

    if (props['google_play_url']) {
        platforms.push('Android');
    }

    return <React.Fragment>
        <Grid item xs={ 12 } sx={{
                'backgroundImage': `url("${ props['splash_background_image'] }")`,
                'backgroundSize': 'cover',
                'backgroundPosition': 'center center',
                'pt': 18,
                'position': 'relative',
        }}>
            <Box position='absolute' left='0' top='0' sx={{
                'backgroundImage': 'url("/images/landing-pages/mosaic.png")',
                'backgroundRepeat': 'repeat',
                'backgroundPosition': 'top left',
                'width': '100%',
                'height': '100%',
            }}/>

            <Box position='absolute' left='0' top='0' pt={ 1 } pl={ 1.5 }>
                <Button aria-label='Home' variant='text' sx={{
                    'color': '#fff',
                    'fontSize': 18,
                    'textTransform': 'none',
                }} onClick={ props['on_home_click'] }>
                    <svg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='#fff' viewBox='0 0 16 16'>
                        <path fillRule='evenodd' d='M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z'/>
                    </svg>
                    &nbsp;&nbsp;Home
                </Button>
            </Box>

            <Grid container maxWidth='lg' ml='auto' mr='auto' px={ 8 } position='relative'>
                <Grid item xs={ 12 } lg={ 7 } textAlign='center' order={{
                    'xs': 2,
                    'lg': 1,
                }}>
                    { is_ipad_visible ? <Box ml='auto' mr='auto' maxWidth={{
                        'xs': '600px',
                        'lg': '560px',
                    }}>
                            <IconButton aria-label='Play Video' sx={{
                                'color': 'rgba(255, 255, 255, 0.5)',
                                'float': {
                                    'xs': 'none',
                                    'lg': 'right',
                                },
                                'padding': 0,
                            }} onClick={ () => window.open(props['youtube_url'], '_self') }>
                                <Box width='500px' height='560px' position='relative' sx={{
                                    'background': 'url("/images/landing-pages/ipad-white-portrait@2x.png") no-repeat center top',
                                    'backgroundSize': '100%',
                                }}>
                                    <Box width='404px' height='500px' position='relative' left='52px' top='53px'>
                                         <img alt='' draggable='false' src={ props['ipad_image'] } style={{
                                            'display': is_ipad_image_loaded ? 'block' : 'none',
                                            'objectFit': 'cover',
                                            'width': '100%',
                                            'height': '100%',
                                            'userSelect': 'none',
                                        }} onLoad={ () => setIsIpadImageLoaded(true) }/>

                                        { !is_ipad_image_loaded ? <Skeleton variant='rectangular' width='100%' height='100%'/> : undefined }
                                    </Box>

                                    <Box position='absolute' left='50%' top='calc(50% + 26px)' width='100px' height='104px' sx={{
                                        'backgroundImage': 'url("/images/landing-pages/play.png")',
                                        'transform': 'translate(-50%, -50%)',
                                    }}/>
                                </Box>
                            </IconButton>

                            <Box width='200px' height='300px' position='absolute' bottom='0' sx={{
                                'backgroundImage': 'url("/images/landing-pages/iphone-white-portrait@2x.png")',
                                'backgroundSize': '100%',
                            }}>
                                <Box width='173px' height='240px' position='relative' left='14px' top='60px'>
                                    <img alt='' draggable='false' src={ props['iphone_image'] } style={{
                                        'display': is_iphone_image_loaded ? 'block' : 'none',
                                        'objectFit': 'cover',
                                        'width': '100%',
                                        'height': '100%',
                                        'userSelect': 'none',
                                    }} onLoad={ () => setIsIphoneImageLoaded(true) }/>

                                    { !is_iphone_image_loaded ? <Skeleton variant='rectangular' width='100%' height='100%'/> : undefined }
                                </Box>
                            </Box>
                        </Box> :
                        <IconButton aria-label='Play Video' sx={{
                            'color': 'rgba(255, 255, 255, 0.5)',
                            'float': {
                                'xs': 'none',
                                'lg': 'right',
                            },
                            'padding': 0,
                        }} onClick={ () => window.open(props['youtube_url'], '_self') }>
                            <Box width='200px' height='340px' sx={{
                                'backgroundImage': 'url("/images/landing-pages/iphone-white-portrait@2x.png")',
                                'backgroundSize': '100%',
                            }}>
                                <Box width='174px' height='270px' position='relative' left='14px' top='60px'>
                                    <img alt='' draggable='false' src={ props['iphone_image'] } style={{
                                        'display': is_iphone_image_loaded ? 'block' : 'none',
                                        'objectFit': 'cover',
                                        'width': '100%',
                                        'height': '100%',
                                        'userSelect': 'none',
                                    }} onLoad={ () => setIsIphoneImageLoaded(true) }/>

                                    { !is_iphone_image_loaded ? <Skeleton variant='rectangular' width='100%' height='100%'/> : undefined }
                                </Box>

                                <Box position='absolute' left='50%' top='calc(50% + 32px)' width='80px' height='83px' sx={{
                                    'backgroundImage': 'url("/images/landing-pages/play.png")',
                                    'backgroundSize': 'contain',
                                    'transform': 'translate(-50%, -50%)',
                                }}/>
                            </Box>
                        </IconButton> }
                </Grid>

                <Grid item xs={ 12 } lg={ 5 } order={{
                    'xs': 1,
                    'lg': 2,
                }} pb={{
                    'xs': 12,
                    'lg': 0,
                }} px={{
                    'xs': 0.5,
                    'sm': 6,
                    'lg': 8,
                }} pt={{
                    'xs': 0,
                    'lg': 4,
                }}>
                    <Box textAlign='center'>
                        <img alt='' width='178' height='178' src={ props['game_icon'] }/>
                    </Box>

                    <Typography component='div' variant='h3' sx={{
                        'color': '#fff',
                        'fontWeight': 300,
                        'textAlign': 'center',
                        'pt': 1,
                    }}>
                        { props['game_name'] }
                    </Typography>

                    <Typography component='div' variant='h5' sx={{
                        'color': '#fff',
                        'fontWeight': 300,
                        'textAlign': 'center',
                        'pt': 1,
                    }}>
                        { props['game_description'] }
                    </Typography>

                    { props['appstore_url'] ? <Box textAlign='center' mt={ 2 }>
                        <IconButton aria-label='Go To AppStore' sx={{
                            'borderRadius': 0,
                            'color': 'rgba(255, 255, 255, 0.5)',
                            'padding': 0,
                        }} onClick={ () => window.open(props['appstore_url'], '_self') }>
                            <img alt='' width='180' height='53' src='/images/landing-pages/badge_apple_top.png'/>
                        </IconButton>
                    </Box> : undefined }

                    { props['google_play_url'] ? <Box textAlign='center' mt={ props['appstore_url'] ? 1 : 2 }>
                        <IconButton aria-label='Go To Google Play' sx={{
                            'borderRadius': 0,
                            'color': 'rgba(255, 255, 255, 0.5)',
                            'padding': 0,
                        }} onClick={ () => window.open(props['google_play_url'], '_self') }>
                            <img alt='' width='180' height='57' src='/images/landing-pages/badge_google_top.png'/>
                        </IconButton>
                    </Box> : undefined }
                </Grid>
            </Grid>

            <Box position='absolute' left='0' bottom='0' sx={{
                'backgroundColor': '#A366FC',
                'backgroundImage': 'url("/images/landing-pages/wood.png")',
                'backgroundRepeat': 'repeat',
                'backgroundPosition': 'top left',
                'filter': 'drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.33))',
                'width': '100%',
                'height': '30px',
            }}/>
        </Grid>

        <Grid item xs={ 12 } pt={ 5 }>
            <Grid container ml='auto' mr='auto' rowGap={ 4 } maxWidth={{
                'xs': 400,
                'md': 'md',
                'lg': 'lg',
            }} px={{
                'xs': 1,
                'sm': 3,
                'md': 4,
                'lg': 8,
            }}>
                { props['features'].map((feature, i) => <Grid key={ i } item xs={ 12 } md={ 4 } px={ 3 }>
                    <Typography component='div' sx={{
                        'color': colors.grey[800],
                        'fontWeight': '400',
                        'fontSize': {
                            'xs': 17,
                            'lg': 22,
                        },
                        'textAlign': 'center',
                    }}>
                        { feature['title'] }
                    </Typography>

                    <Paper elevation={ 2 } sx={{
                        'position': 'relative',
                        'px': 1,
                        'py': 1,
                        'pb': 0.9,
                        'mt': 1.5,
                    }}>
                        <Skeleton variant='rounded' sx={{
                            'width': '100%',
                            'height': 0,
                            'pt': '58.33%',
                        }}/>

                        <Box position='absolute' left='0' top='0' padding='inherit'>
                            <img alt='' draggable='false' width='600' height='350' src={ feature['image'] } style={{
                                'borderRadius': 4,
                                'display': 'none',
                                'width': '100%',
                                'height': 'auto',
                                'userSelect': 'none',
                            }} onLoad={ (e) => { e.target.style.display = 'block'; }}/>
                        </Box>
                    </Paper>

                    { feature['description'] ? <Typography component='div' sx={{
                        'color': colors.grey[700],
                        'fontWeight': '300',
                        'fontSize': 15,
                        'textAlign': 'center',
                        'px': 0.5,
                        'pt': 2,
                    }}>
                            { feature['description'] }
                    </Typography> : undefined }
                </Grid>) }
            </Grid>
        </Grid>

        <Grid item xs={ 12 }>
            <Box display='flex' justifyContent='center' ml='auto' mr='auto' width={{
                'xs': 240,
                'sm': 320,
                'md': 480,
                'lg': 640,
            }} height={{
                'xs': 135,
                'sm': 180,
                'md': 270,
                'lg': 360,
            }} mt={{
                'xs': 4,
                'sm': 6,
                'md': 8,
            }}>
                <iframe title={ `${ props['game_name'] } Trailer` } src={ props['youtube_embed_url'] } frameBorder='0' allowFullScreen='' style={{
                    'display': is_video_iframe_loaded ? 'block' : 'none',
                    'width': '100%',
                    'height': '100%',
                }} onLoad={ () => setIsVideoIframeLoaded(true) }/>

                { !is_video_iframe_loaded ? <Skeleton variant='rectangular' sx={{
                    'width': '100%',
                    'height': '100%',
                }}
                /> : undefined }
            </Box>
        </Grid>

        <Grid item xs={ 12 } pt={ 10 } px={ 2 } sx={{
            'textAlign': 'center',
        }} pb={{
            'xs': 11,
            'md': 7,
        }}>
                <Typography component='div' variant='h4' sx={{
                    'color': colors.grey[700],
                }}>
                    Play <span style={{ 'color': colors.grey[900] }}>{ props['game_name'] }</span> on { platforms.join(' and ') }!
                </Typography>

                { props['appstore_url'] ? <Box textAlign='center' mt={ 4 }>
                    <IconButton aria-label='Go To AppStore' sx={{
                        'borderRadius': 0,
                        'color': 'rgba(255, 255, 255, 0.5)',
                        'padding': 0,
                    }} onClick={ () => window.open(props['appstore_url'], '_self') }>
                        <img alt='' width='244' height='72' src='/images/landing-pages/badge_apple.png'/>
                    </IconButton>
                </Box> : undefined }

                { props['google_play_url'] ? <Box textAlign='center' mt={ props['appstore_url'] ? 1 : 4 }>
                    <IconButton aria-label='Go To Google Play' sx={{
                        'borderRadius': 0,
                        'color': 'rgba(255, 255, 255, 0.5)',
                        'padding': 0,
                    }} onClick={ () => window.open(props['google_play_url'], '_self') }>
                        <img alt='' width='244' height='77' src='/images/landing-pages/badge_google.png'/>
                    </IconButton>
                </Box> : undefined }

                <Box textAlign='center' mt={ 12 }>
                    <IconButton aria-label='Contact Us' sx={{
                        'borderRadius': '50%',
                        'color': colors.blue[200],
                        'padding': 0,
                        'position': 'relative',
                        'width': 105,
                        'height': 105,
                    }} onClick={ () => window.open('mailto:info@pandasticgames.com', '_self') }>
                        <Box width='100%' height='100%' sx={{
                            'backgroundColor': colors.blue[400],
                            'borderRadius': '50%',
                        }}/>

                        <Box position='absolute' left='50%' top='54%' sx={{
                            'transform': 'translate(-50%, -50%)',
                        }}>
                            <svg xmlns='http://www.w3.org/2000/svg' width='64' height='64' fill='#fff' viewBox='0 0 16 16'>
                                <path d='M.05 3.555A2 2 0 0 1 2 2h12a2 2 0 0 1 1.95 1.555L8 8.414.05 3.555ZM0 4.697v7.104l5.803-3.558L0 4.697ZM6.761 8.83l-6.57 4.027A2 2 0 0 0 2 14h12a2 2 0 0 0 1.808-1.144l-6.57-4.027L8 9.586l-1.239-.757Zm3.436-.586L16 11.801V4.697l-5.803 3.546Z'/>
                            </svg>
                        </Box>
                    </IconButton>

                    <Typography component='div' sx={{
                        'color': colors.grey[800],
                        'fontSize': 18,
                        'mt': 3,
                        'mb': 4,
                    }}>
                        E-mail our customer support with your comments or suggestions!
                    </Typography>

                    <Link href='mailto:info@pandasticgames.com' sx={{
                        'color': colors.blue[500],
                        'fontSize': 29,
                        'textDecoration': 'none',
                    }}>
                        Contact Us
                    </Link>
                </Box>
        </Grid>
    </React.Fragment>;
});

export default LandingPageScreen;
